import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
// import { useTranslation } from 'react-i18next';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';

import { SectionTitle } from '../utils/GlobalFormatting';

// import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(40)} auto 0;
  padding-bottom: ${rem(80)};
`;

// const Section = styled.div`
//   justify-content: center;
//   /* margin-bottom: ${rem(40)};

//   ${breakpoint('large')} {
//     margin-bottom: ${rem(50)};
//   } */
// `;

// const Department = styled(SectionTitle)`
//   font-size: ${rem(20)};
//   letter-spacing: 0.01em;
//   padding-top: 1.1rem;
//   border-top: 2px solid ${(props) => props.theme.palette.colors.sixt};

//   ${breakpoint('mediumlarge')} {
//     font-size: ${rem(22)};
//   }

//   ${breakpoint('large')} {
//     font-size: ${rem(24)};
//   }
// `;

// const StaffCol = styled(Col)`
//   margin: 0 0 ${rem(30)};
//   width: 100%;

//   ${breakpoint('medium')} {
//     width: 50%;
//   }

//   ${breakpoint('mediumlarge')} {
//     width: 33.333%;
//   }

//   ${breakpoint('large')} {
//     width: 25%;
//   }
// `;

function Terms() {
  // const { t } = useTranslation();

  // useLangRedirect(page);

  return (
    <Layout>
      <SEO title="Köpvillkor" />

      <Hero
        heading="Köpvillkor"
        // image={featuredMedia?.localFile}
        pageTitle
        // preHeading="Pre heading"
        // actions="Actions"
        tint
      />

      <Container>
        <Row>
          <Col>
            <SectionTitle> Allmänna villkor för detta köp</SectionTitle>
            <p>
              Dessa allmänna villkor gäller när du som konsument, (nedan nämnd
              Kunden), gör en beställning via webbplatsen: publikfigur.se och
              därtill hörande webbsidor.
            </p>
            <p>
              Avtal ingås mellan dig (”Kunden”) och Nordsign AB med
              organisationsnummer 556699-2706 nedan benämnd säljaren.
            </p>
            <p>
              Utförliga kontaktuppgifter och övrig information om säljaren
              framgår på Webbplatsen.
            </p>
            <p>
              För att genomföra en beställning måste Kunden ha fyllt 18 år,
              eller ha målsmans medgivande Med kund avses en fysisk person.
            </p>
            <p>
              Säljaren accepterar enligt svensk lag inte kreditköp till personer
              under 18 år. Säljaren förbehåller sig rätten att i enskilda fall
              neka eller ändra en Kunds beställning (exempelvis om Kunden
              uppgivit felaktiga personuppgifter).
            </p>
            <p>
              Vid köp av en publikfigur äger köparen fullt ansvar för vilken
              bild som används, samt ger publikfigur.se rätten att tillverka
              figuren med ditt valda motiv, och placera ut den på slumpmässigt
              vald läktarplats hos den idrottsförening som köparen valt vid
              köpet. Om bilden som laddas upp innehåller stötande eller annan
              olaglig information kommer dessa ej att placeras ut. Ingen
              återbetalning för utebliven figur kommer att ske.
            </p>
            <p>
              Kunden godkänner genom att fullfölja affären, samtliga villkor i
              dessa allmänna villkor.
            </p>

            <h3>Återförsäljare</h3>
            <p>
              Nordsign AB är återförsäljare. Detta förfarande medför att
              mottagaren av betalning från kund är Nordsign AB.
            </p>
            <p>
              Kunden är fullt införstådd med att Nordsign AB endast agerar såsom
              återförsäljare och kan inte hållas ansvarig för något annat än
              försäljning av produkt/tjänst och därtill hörande betalningen. Vid
              uteblivna evenemang sker ingen återbetalning.
            </p>

            <h3>Villkor för figurköp</h3>
            <p>
              En bokning kan ångras fram till dess att ordern är lagd.
              Distansavtalslagens regler om ångerrätt (för privatpersoner)
              gäller ej vid köp av publikfigur. När betalning har skett är
              kunden därför bunden av sitt köp.
            </p>

            <h3>Köp som strider mot uppsatta villkor</h3>
            <p>
              Säljaren förbehåller sig rätten att makulera bokning eller köp vid
              de fall kunden inte lämnar korrekta uppgifter om sig själv eller i
              övrigt bryter mot eller försöker kringgå dessa villkor.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Terms;
